export default (e, rotation = 0) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    let finalX = x;
    let finalY = y + (rect.width / 2);

    switch (rotation) {
    case -90:
        finalX = Math.abs(y - rect.height);
        finalY = x + (rect.height / 2);
        break;
    case 90:
        finalX = rect.height - Math.abs(rect.height - y);
        finalY = Math.abs(x - rect.height);
        break;
    case 180:
        finalX = Math.abs(x - rect.width);
        finalY = Math.abs(y - (rect.height * 2));
        break;
    default:
        finalX = x;
        finalY = y + (rect.width / 2);
        break;
    }
    e.target.querySelector('.background-fill').style.transformOrigin = `${finalX}px ${finalY}px`;
};
