import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Observer } from 'gsap/Observer';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { TextPlugin } from 'gsap/TextPlugin';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { SplitText } from 'gsap/SplitText';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { Flip } from 'gsap/Flip';

gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin, TextPlugin, InertiaPlugin, SplitText, Flip);
gsap.defaults({ ease: 'power4' });
// gsap.config({ force3D: true, autoSleep: 2400 });
gsap.config({ autoSleep: 2400 });

export {
    gsap, ScrollTrigger, Observer, ScrollToPlugin, TextPlugin, InertiaPlugin, SplitText, Flip,
};
