import { createApp } from 'vue';
import '@/assets/styles/fonts.css';
import '@/assets/styles/main.css';
import '@/../vendor/verbb/formie/src/web/assets/frontend/dist/css/fields/phone-country.css';
import App from '@/app.vue';
import VueGtag from 'vue-gtag';
import router from '@/composables/router.js';

const app = createApp(App);
app.use(router);
app.use(VueGtag, {
    config: {
        id: 'GTM-K4P7QCBL',
    },
}, router);
app.mount('#app');
