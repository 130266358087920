<script setup>
import { ref } from 'vue';
import { useCursor } from '@/composables/use-cursor.js';

const el = ref(null);

useCursor(el);

</script>

<template>
    <span ref="el" class="cursor-wrapper js-cursor opacity-0">
        <span class="cursor cursor-hidden cursor-default" />
        <span class="cursor cursor-bar-vertical cursor-hidden"><span class="bg-primary-light block h-full w-px" style="min-height: 15px;" /></span>
        <span class="cursor cursor-circle-white cursor-hidden" />
        <span class="cursor cursor-circle-black cursor-hidden" />
        <span class="cursor cursor-circle-primary cursor-hidden" />
        <span class="cursor cursor-loading cursor-hidden">
            <svg viewBox="0 0 26 26">
                <path
                    class="cursor-loading-circle"
                    fill="none"
                    fill-rule="evenodd"
                    d="M3.135 6.29c2.54-3.758 7.15-6 12.197-5.086 4.747.862 8.598 4.653 9.52 9.387C26.342 18.275 20.497 25 13.084 25a11.987 11.987 0 0 1-9.95-5.29"
                />
            </svg></span>
    </span>
</template>
