query MyQueryApp ($lang: [String], $slug: [String], $uri: [String]){
  menuMain: navigationNodes(navHandle: "main", site: $lang) {
    title,
    url
    newWindow
    classes
  },
  menuCredential: navigationNodes(navHandle: "credentials", site: $lang) {
    title,
    url
    newWindow
    classes
  },
  menuFooter: navigationNodes(navHandle: "footer", site: $lang) {
    title,
    url
    newWindow
    classes
  },
  footer: globalSet(handle: "footer", site: $lang) {
    ... on footer_GlobalSet {
      image @transform(format: "webp", quality: 80, immediately: true, height: 172) {
        id,
        url,
        alt,
        srcset(sizes:["320w", "640w", "960w", "1280w"])
      },
      socialsMedias {
        ... on socialsMedias_socialMedia_BlockType {
          id
          weblink
          icon {
            inline
          }
        }
      }
    }
  },
  entry(slug: $slug, uri: $uri, site: $lang) {
      id
      siteId
      siteHandle
      url
      localized{
          id
          siteId
          siteHandle
          url
      }
  }
}
