import { computed, ref } from 'vue';
import { useLenis } from '@/composables/lenis.js';

const { CRAFT_SITES } = window;
const lenis = useLenis();

const currentEntry = ref({});
const scrollPositions = ref({});
const isTransitioning = ref(false);
const isLoading = ref(true);
const scrollTop = ref(0);

lenis.value.on('scroll', () => {
    scrollTop.value = lenis.value.scroll;
});

const useStore = () => ({
    isLoading,
    isTransitioning,
    scrollPositions,
    currentEntry,
    scrollTop,
    lang: computed(() => {
        let current = 'fr';

        /**
         * @todo Change this for a better detection
         * Can be faulty if baseUrl is not sorted in the right order
         */
        for (let i = 0, il = CRAFT_SITES.length; i < il; ++i) {
            const site = CRAFT_SITES[i];
            if (window.location.href.startsWith(site.baseUrl)) {
                current = site.handle;
            }
        }

        return current;
    }),
    site: computed(() => {
        let current = CRAFT_SITES[0];

        /**
         * @todo Change this for a better detection
         * Can be faulty if baseUrl is not sorted in the right order
         */
        for (let i = 0, il = CRAFT_SITES.length; i < il; ++i) {
            const site = CRAFT_SITES[i];
            if (window.location.href.startsWith(site.baseUrl)) {
                current = site;
            }
        }

        return current;
    }),
    primarySite: computed(() => {
        let current = CRAFT_SITES[0];

        /**
         * @todo Change this for a better detection
         * Can be faulty if baseUrl is not sorted in the right order
         */
        for (let i = 0, il = CRAFT_SITES.length; i < il; ++i) {
            const site = CRAFT_SITES[i];
            if (site.primary) {
                current = site;
            }
        }

        return current;
    }),
});

export default useStore;
