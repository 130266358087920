import { ref } from 'vue';
import Lenis from '@studio-freight/lenis';
import Tempus from '@studio-freight/tempus';
import { gsap, ScrollTrigger } from '@/composables/gsap.js';

const lenis = ref(null);

const useLenis = (options = { lerp: 0.02 }) => {
    if (!lenis.value) {
        lenis.value = new Lenis(options);
        lenis.value.on('scroll', () => {
            ScrollTrigger.update();
        });
        gsap.ticker.remove(gsap.updateRoot);
        Tempus.add((time) => {
            gsap.updateRoot(time / 1000);
            lenis.value.raf(time);
        }, 0);
    }

    return lenis;
};

export { useLenis, lenis };
