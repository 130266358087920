import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes.js';
import useStore from '@/composables/store.js';

const store = useStore();

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_BASE_PUBLIC_PATH),
    routes,
    scrollBehavior: () => null,
});

if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

router.beforeEach((to, from, next) => {
    /**
     * Browser events (prev / next) trigger popstate events
     * We want to reset scroll position for those, but not regular navigation events
     * (clicking on a link that's not labeled as "back", user expects to get back to the top of the page)
     *
     * Manually set "window.VUE_IS_BACK" to true on click of a "back" ui button if need be
     */
    if (window.event?.type !== 'popstate' || !window.VUE_IS_BACK) {
        store.scrollPositions[to.path] = 0;
        window.VUE_IS_BACK = false;
    }

    if (from.path) {
        store.scrollPositions[from.path] = window.scrollY;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'virtualPageView',
        virtualPagePath: to.path,
    });

    next();
});

export default router;
