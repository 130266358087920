/* eslint-disable no-param-reassign */
import {
    useClient, cache, dedup,
} from 'villus';
import { useRoute } from 'vue-router';
import { batch } from '@villus/batch';
import useStore from '@/composables/store.js';

const store = useStore();

export default function getClient() {
    const route = useRoute();

    // Fix preview in Craft they add query parameters dynamically and it doesn't trigger re-rendering automatically
    const computedURLPlugin = ({ opContext }) => {
        if (route.query.token && route.query['x-craft-live-preview']) {
            opContext.url = `${store.site.value.baseUrl}api/?x-craft-live-preview=${route.query['x-craft-live-preview']}&token=${route.query.token}`;
        } else {
            opContext.url = `${store.site.value.baseUrl}api/`;
        }
    };
    const craftURL = route.query.token && route.query['x-craft-live-preview']
        ? `${store.site.value.baseUrl}api/?x-craft-live-preview=${route.query['x-craft-live-preview']}&token=${route.query.token}`
        : `${store.site.value.baseUrl}api/`;
    return useClient({
        url: craftURL,
        use: [computedURLPlugin, batch({ timeout: 5 }), cache(), dedup()],
    });
}
