// import NotFound from '@/pages/404.vue';

const routes = [
    { path: '/404/', name: 'fr-CA.404', component: () => import('@/pages/404.vue') },
    { path: '/(.*)*', redirect: { name: 'fr-CA.404' } },
];

const pageComponents = import.meta.glob('./pages/**/*.vue');
const sections = Object.keys(window.CRAFT_ROUTES.sections);
const sites = [];

function addSection(section) {
    const currentSite = sites[section.siteId];
    const componentKey = `./pages/${section.template}.vue`;
    const siteRelativeUrl = currentSite.baseUrl.replace(window.CRAFT_BASE, '');
    const sitePrefix = siteRelativeUrl.replace(/\/$/, '');
    routes.push({
        path: `${sitePrefix}${section.url}`,
        component: pageComponents[componentKey],
        name: `${currentSite.language}.${section.handle}`,
        meta: {
            siteId: currentSite.id,
        },
    });
}

/**
 * Format sites
 */
for (let i = 0, il = window.CRAFT_SITES.length; i < il; ++i) {
    const site = window.CRAFT_SITES[i];
    sites[site.id] = site;
}

for (let i = 0, il = sections.length; i < il; ++i) {
    const sectionKey = sections[i];
    const siteSections = window.CRAFT_ROUTES.sections[sectionKey];
    if (typeof siteSections.siteId === 'undefined') {
        const siteSectionsKeys = Object.keys(siteSections);
        for (let y = 0, yl = siteSectionsKeys.length; y < yl; ++y) {
            const siteSectionKey = siteSectionsKeys[y];
            const siteSection = siteSections[siteSectionKey];
            addSection(siteSection);
        }
    } else {
        addSection(siteSections);
    }
}

export default routes;
