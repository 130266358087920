import { onMounted, ref } from 'vue';
import Cursor from '@/composables/cursor.js';
import isTouchDevice from '@/composables/is-touch.js';

const cursorHandler = ref(null);

function useCursor(el) {
    if (!el) {
        return cursorHandler;
    }

    onMounted(() => {
        if (!cursorHandler.value) {
            if (!isTouchDevice()) {
                cursorHandler.value = new Cursor(el.value);
            } else {
                document.documentElement.classList.add('no-touch');
                cursorHandler.value = false;
            }
        }
    });

    return cursorHandler;
}

export { useCursor, isTouchDevice };
