import {
    computed, watch, ref,
} from 'vue';
import { useQuery } from 'villus';
import { useRoute } from 'vue-router';
import useStore from '@/composables/store.js';

const useSeo = () => {
    const store = useStore();
    const route = useRoute();

    const seo = ref({
        title: '',
        description: '',
    });

    const variables = computed(() => ({
        path: route.path,
        uri: route.path.replace(`/${store.lang.value}`, ''),
        siteId: store.site.value.id,
    }));

    const { data } = useQuery({
        variables,
        query: `
    query FetchEntries($uri: String, $siteId: Int) {
        seomatic (uri: $uri, siteId: $siteId, asArray: true) {
            metaTitleContainer
        }
    }
`,
    });

    watch(data, () => {
        if (!data.value) return;
        Object.keys(data.value.seomatic).forEach((k) => {
            const v = data.value.seomatic[k];
            if (typeof v === 'string') data.value.seomatic[k] = JSON.parse(v);
        });
        seo.value.title = data.value.seomatic.metaTitleContainer.title.title;
        document.title = seo.value.title;
    });
};

export default useSeo;
